import React from "react"
import PropTypes from "prop-types"

import { useThemeUI } from "theme-ui"
import Box from "./box"

const PaperStack = ({ p, borderColor, children }) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Box
      bg="background"
      p={p}
      sx={{
        borderWidth: 1,
        borderRadius: "sm",
        borderStyle: "solid",
        borderColor: borderColor,
        boxShadow: `5px 5px 0 -1px ${theme.colors["background"]},5px 5px 0 ${theme.colors[borderColor]},10px 10px 0 -1px ${theme.colors["background"]},10px 10px 0 ${theme.colors[borderColor]}`,
        width: "100%",
      }}
    >
      {children}
    </Box>
  )
}

PaperStack.propTypes = {
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  borderColor: PropTypes.string,
}

PaperStack.defaultProps = {
  p: [6, 8],
  borderColor: "border",
}

export default PaperStack

import React from "react"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import AudioPlayer from "../../../../../components/audio-player"
import Box from "../../../../../components/box"
import Bullet from "../../../../../components/bullet"
import BulletList from "../../../../../components/bullet-list"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Inline from "../../../../../components/inline"
import NoBreak from "../../../../../components/no-break"
import PaperStack from "../../../../../components/paper-stack"
import QuoteBox from "../../../../../components/quote-box"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "wie-sah-hoelderlin-aus",
}

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const wirkung = getAnswer(taskProps)
  const beschreibung = getAnswer({
    ...taskProps,
    taskId: "beschreibung",
  })

  return (
    <LayoutCourse
      navigation={navigation}
      progress={progress}
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisetagebuch" />
      }
    >
      <Seo title="So sah Hölderlin aus" />
      <Stack>
        <Heading as="h1" level={2}>
          So sah Hölderlin aus
        </Heading>
        <Grid columns={[1, 2]}>
          <PaperStack>
            <Stack space={6}>
              <Paragraph>Hölderlin wirkt auf dich ...</Paragraph>
              <Inline>
                {wirkung &&
                  Object.values(wirkung).map((value, i) => {
                    return (
                      value && (
                        <Box
                          key={`wirkung-${i}`}
                          sx={{
                            bg: "muted",
                            p: 3,
                            borderRadius: "9999em",
                            color: "text",
                            fontSize: 2,
                            fontFamily: "sans",
                            fontWeight: "bold",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {value}
                        </Box>
                      )
                    )
                  })}
              </Inline>
              {beschreibung && (
                <BulletList size={3}>
                  {Object.values(beschreibung)
                    .filter((value) => value && value !== "")
                    .map((value, i) => (
                      <Bullet key={`beschreibung-${i}`}>{value}</Bullet>
                    ))}
                </BulletList>
              )}
            </Stack>
          </PaperStack>
          <Stack space={6}>
            <Paragraph>
              Das sagte ein Schulfreund über <NoBreak>Hölderlin</NoBreak>:
            </Paragraph>
            <QuoteBox size={[3, 3, 4]}>
              Seine regelmäßige Gesichtsbildung, der sanfte Ausdruck seines
              Gesichts, sein schöner Wuchs, sein sorgfältiger reinlicher Anzug
              und jener unverkennbare Ausdruck des Höheren in seinem ganzen
              Wesen sind mir immer gegenwärtig geblieben.
            </QuoteBox>
            <AudioPlayer src={`kurse/hoelderlins-reisen/beschreibung`} />
          </Stack>
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
